import { createSlice } from '@reduxjs/toolkit'

import { US_STATES } from 'constants/us-states'
import { TIMEZONES } from 'constants/timezones'
import {
  fetchTransactionTypes,
  fetchReservationTypes,
  fetchSponsorTypes,
  fetchAddressTypes,
  fetchFitKitTypes,
  fetchSupplyTransactionTypes,
  fetchAdjustmentTransactionTypes,
  fetchReservationStatusesType,
  fetchTags,
  fetchStudioEventActionTypes,
  fetchChallengeTypes,
  fetchAmenities,
} from 'store/actions/services.actions'

const enumsSlice = createSlice({
  name: 'enums',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    transactionTypes: [],
    reservationTypes: [],
    sponsorTypes: [],
    challengeTypes: [],
    addressTypes: [],
    fitKitTypes: [],
    supplyTransactionTypes: [],
    adjustmentTransactionTypes: [],
    reservationStatusesTypes: [],
    tags: [],
    amenities: [],
    studioEventActionTypes: [],
    statesOptions: US_STATES,
    timezonesOptions: TIMEZONES,
    userActivityEventNames: [{ id: 'Workout: Finish', label: 'Workout: Finish', value: 'Workout: Finish' }], // hard code for now
    userActivityWorkoutCategories: [{ id: 'Gym', label: 'Gym', value: 'Gym' }], // hard code for now
  },
  reducers: {},
  extraReducers: {
    [`${fetchTransactionTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchReservationTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchSponsorTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchChallengeTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchAddressTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchFitKitTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchSupplyTransactionTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchAdjustmentTransactionTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchReservationStatusesType.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchTags.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchAmenities.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchStudioEventActionTypes.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchTransactionTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchReservationTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchSponsorTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchChallengeTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchAddressTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchFitKitTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchSupplyTransactionTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchAdjustmentTransactionTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchReservationStatusesType.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchTags.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchStudioEventActionTypes.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchTransactionTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, transactionTypes: [...action.payload] }
    },
    [`${fetchReservationTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, reservationTypes: [...action.payload?.results] }
    },
    [`${fetchSponsorTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, sponsorTypes: [...action.payload?.results] }
    },
    [`${fetchChallengeTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, challengeTypes: [...action.payload?.results] }
    },
    [`${fetchAddressTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, addressTypes: [...action.payload?.results] }
    },
    [`${fetchFitKitTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, fitKitTypes: [...action.payload?.results] }
    },
    [`${fetchSupplyTransactionTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, supplyTransactionTypes: [...action.payload] }
    },
    [`${fetchAdjustmentTransactionTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, adjustmentTransactionTypes: [...action.payload] }
    },
    [`${fetchReservationStatusesType.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, reservationStatusesTypes: [...action.payload] }
    },
    [`${fetchTags.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, tags: [...action.payload?.results] }
    },
    [`${fetchAmenities.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, amenities: [...action.payload?.results] }
    },
    [`${fetchStudioEventActionTypes.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, studioEventActionTypes: [...Object.values(action?.payload)] }
    },
  },
})

export default enumsSlice.reducer
